section{
    width: 80%;
    margin: auto;
    margin-top: 5%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
}

.cards{
    width: 250px;
    padding: 20px;
}
.cards:hover{
    box-shadow: 0 10px 10px 10px rgba(0,0,0,0.1);
    cursor: pointer;
}
.image_box img{
    width: 150px;
    height: 200px;
}
.details p:nth-child(1){
    font-weight: bold;
}
.details p:nth-child(2){
    color: grey;
    font-size: 0.8rem;
}
.details p:nth-child(3){
    font-weight: bold;
    color: grey;
}
.details button{
    padding: 5px 10px;
    border: none;
    font-weight: bold;
    background-color: skyblue;
    outline: none;
    border-radius: 2px;
}