.dynamic-image-container {
    position: relative;
    overflow: hidden;
  }
  
  .image-wrapper {
    
    position: relative;
    transition: transform 0.5s ease;
  }
  
  .image-wrapper img {
    width: 100;
    height: 100;
    
  }
  
  .image-wrapper img:not(.active) {
    position: absolute;
    left: 100%;
  }
  
  .image-wrapper img.active {
    animation: slideIn 3s forwards;
  }
  
  @keyframes slideIn {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  